<template>
  <div
    class="list-top"
    :style="{
      backgroundColor: 'var(--tc-color-bg-list)',
    }"
  >
    <BusinessBUserHeader />
    <MobileSearchDestination
      :refrehs-on-confirm="true"
      p="y-4 x-4.5"
      :default-style="false"
      :style="{ background: `url(${bgImg}) center / cover no-repeat` }"
    />
  </div>
</template>

<script setup lang="ts">
const { bgImg } = usePartnerConfig()
</script>

<style lang="scss" scoped>
.list-top {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 260px;
  position: relative;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 40px;

    .title-t1 {
      font-size: 22px;
      font-weight: 600;
      color: #ffffff;

      margin: 0 18px;
      text-align: center;
    }

    .title-t2 {
      margin-top: 5px;
      font-size: 22px;
      font-weight: 600;
      color: #ffffff;
    }

    .title-sub {
      margin-top: 5px;
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
    }
  }

  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 18px 20px 18px;
    background-color: white;
    border-radius: 22px;
    height: 36px;

    &-left {
      height: 36px;
      background-color: #000000;

      display: flex;
      flex-direction: row;
      align-items: center;

      max-width: 200px;
      border-radius: 22px;

      &-city {
        margin-left: 16px;
        font-weight: 600;
        color: #ffffff;

        // 可以被压缩，点点点
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-triangle {
        margin: 8px 12px 0 10px;
        // margin-left: 6px;
        // margin-right: 12px;
        // width: 21px;
        // height: 21px;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-top: 8px solid #ffffff;
      }
    }

    &-right {
      flex: 1;
      &-t {
        margin-left: 10px;
        color: #b3b3b3;

        font: {
          size: 12px;
          weight: 300;
        }
      }
    }
  }
}
</style>
